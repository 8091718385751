.drawer__inner__animal_crossing {
  display: inline;
  position: absolute;
  right: 5px;
  bottom: 5px;

  img {
    width: 50px;
  }
}
